'use client';

import * as React from 'react';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { Github, Mail, Twitter } from 'lucide-react';

import { FooterLink } from './FooterLink';

interface SocialIconProps {
  href: string;
  icon: React.ElementType;
  label: string;
}

const SocialIcon: React.FC<SocialIconProps> = ({ href, icon: Icon, label }) => (
  <motion.a
    href={href}
    target='_blank'
    rel='noopener noreferrer'
    aria-label={label}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
    className='flex h-10 w-10 items-center justify-center rounded-full border border-white/10 bg-white/5 text-gray-400 transition-colors hover:bg-white/10 hover:text-white'
  >
    <Icon size={18} />
  </motion.a>
);

interface FooterSectionProps {
  title: string;
  children: React.ReactNode;
}

const FooterSection: React.FC<FooterSectionProps> = ({ title, children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
    className='space-y-4'
  >
    <h3 className='mb-3 font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600'>
      {title}
    </h3>
    {children}
  </motion.div>
);

export const Footer: React.FC = () => (
  <footer className='relative overflow-hidden border-t border-white/10 py-16'>
    {/* Background gradient elements */}
    <div className='absolute inset-0 z-0 overflow-hidden'>
      <div className='absolute -bottom-40 -right-40 h-80 w-80 rounded-full bg-blue-500/5 blur-3xl' />
      <div className='absolute -top-40 -left-40 h-80 w-80 rounded-full bg-purple-500/5 blur-3xl' />
    </div>

    <div className='container relative z-10 mx-auto max-w-6xl px-4'>
      <div className='mb-12 grid grid-cols-1 gap-8 md:grid-cols-4'>
        {/* Company Info */}
        <div className='space-y-4'>
          <Link
            href='/'
            className='inline-block text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600 transition-all duration-300 hover:scale-105'
          >
            DysektAI
          </Link>
          <p className='text-sm text-gray-400'>
            Pushing the boundaries of what's possible in modern web development with cutting-edge AI
            technology.
          </p>
          <div className='flex space-x-3'>
            <SocialIcon href='https://github.com/dysektai' icon={Github} label='GitHub' />
            <SocialIcon href='https://x.com/dys_ai' icon={Twitter} label='Twitter' />
            <SocialIcon href='mailto:contact@dysektai.com' icon={Mail} label='Email' />
          </div>
        </div>

        {/* Company & Main Links */}
        <FooterSection title='Company'>
          <ul className='space-y-2'>
            <li>
              <FooterLink href='/'>Home</FooterLink>
            </li>
            <li>
              <FooterLink href='/about'>About</FooterLink>
            </li>
            <li>
              <FooterLink href='/contact'>Contact Us</FooterLink>
            </li>
          </ul>
        </FooterSection>

        {/* Resources */}
        <FooterSection title='Resources'>
          <ul className='space-y-2'>
            <li>
              <FooterLink href='/fmwheels'>Domains</FooterLink>
            </li>
            <li>
              <FooterLink href='/pgp'>PGP Keys</FooterLink>
            </li>
            <li>
              <FooterLink
                href='https://tarkovtracker.org'
                target='_blank'
                rel='noopener noreferrer'
              >
                TarkovTracker.org
              </FooterLink>
            </li>
            <li>
              <FooterLink href='https://eft.dysektai.com'>EFT Utils</FooterLink>
            </li>
          </ul>
        </FooterSection>

        {/* Legal */}
        <FooterSection title='Legal'>
          <ul className='space-y-2'>
            <li>
              <FooterLink href='/privacy-policy'>Privacy Policy</FooterLink>
            </li>
            <li>
              <FooterLink href='/terms-of-service'>Terms of Service</FooterLink>
            </li>
          </ul>
        </FooterSection>
      </div>

      {/* Bottom Bar */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className='border-t border-white/10 pt-8 text-center'
      >
        <p className='text-gray-400'>
          &copy; {new Date().getFullYear()} DysektAI. All rights reserved.
        </p>
      </motion.div>
    </div>
  </footer>
);
