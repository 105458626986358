'use client';

import { useCallback, useMemo, useState, type FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { createClient } from '@/utils/supabase/client';
import { User } from '@supabase/supabase-js';
import { motion } from 'framer-motion';
import { LogOut, Menu, User as UserIcon, X } from 'lucide-react';

import { AuthButton } from './AuthButton';
import { ClientNav } from './ClientNav';
import { NavLink } from './NavLink';

interface UserProfile {
  username?: string;
  avatar_url?: string;
  full_name?: string;
}

export const Header: FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const supabase = createClient();
  const router = useRouter();

  const handleSignOut = useCallback(async () => {
    await supabase.auth.signOut();
    router.push('/');
    router.refresh();
    setIsDropdownOpen(false);
  }, [supabase, router, setIsDropdownOpen]);

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(prev => !prev);
  }, []);

  const navItems = useMemo(
    () => [
      { href: '/', label: 'Home' },
      { href: '/about', label: 'About' },
      { href: '/contact', label: 'Contact' },
    ],
    [],
  );

  const memoizedClientNav = useMemo(
    () => (
      <ClientNav>
        {navItems.map(item => (
          <li key={item.href}>
            <NavLink href={item.href}>{item.label}</NavLink>
          </li>
        ))}
        <div className='relative'>
          <AuthButton>
            {(user: User | null, profile: UserProfile | null) => (
              <>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className='relative flex h-9 w-9 items-center justify-center rounded-full border-2 border-white/10 bg-white/5 transition-colors hover:bg-white/10 hover:border-white/20'
                >
                  {profile?.avatar_url ? (
                    <Image
                      src={profile.avatar_url}
                      alt='Profile'
                      fill
                      className='rounded-full object-cover'
                    />
                  ) : (
                    <UserIcon className='h-5 w-5 text-white/60' />
                  )}
                </motion.button>
                {isDropdownOpen && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className='absolute right-0 mt-2 w-48 rounded-xl border border-white/10 bg-black/80 py-1 backdrop-blur-md'
                  >
                    <Link
                      href='/dashboard'
                      onClick={() => setIsDropdownOpen(false)}
                      className='flex items-center gap-2 px-4 py-2 text-sm text-white/80 transition-colors hover:bg-white/5'
                    >
                      <UserIcon className='h-4 w-4' />
                      Dashboard
                    </Link>
                    <button
                      onClick={handleSignOut}
                      className='flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-red-400/80 transition-colors hover:bg-white/5'
                    >
                      <LogOut className='h-4 w-4' />
                      Sign Out
                    </button>
                  </motion.div>
                )}
              </>
            )}
          </AuthButton>
        </div>
      </ClientNav>
    ),
    [setIsDropdownOpen, isDropdownOpen, handleSignOut, navItems],
  );

  return (
    <motion.header
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
      className='fixed left-0 right-0 top-6 z-50 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'
    >
      <motion.nav
        className='flex h-16 items-center justify-between rounded-full border border-white/10 bg-gradient-to-r from-black/60 to-black/80 px-6 backdrop-blur-xl'
        whileHover={{ boxShadow: '0 0 20px 0 rgba(96, 165, 250, 0.3)' }}
      >
        <Link
          href='/'
          className='relative text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600 transition-all duration-300 hover:scale-105'
        >
          DysektAI
        </Link>

        {/* Desktop Navigation */}
        <div className='hidden md:block'>{memoizedClientNav}</div>

        {/* Mobile Menu Button */}
        <div className='md:hidden'>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={toggleMobileMenu}
            className='flex h-9 w-9 items-center justify-center rounded-full border border-white/10 bg-white/5'
            aria-label='Toggle menu'
          >
            {isMobileMenuOpen ? (
              <X className='h-5 w-5 text-white' />
            ) : (
              <Menu className='h-5 w-5 text-white' />
            )}
          </motion.button>
        </div>
      </motion.nav>

      {/* Mobile Navigation */}
      {isMobileMenuOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className='absolute left-0 right-0 mt-2 rounded-xl border border-white/10 bg-black/80 py-4 backdrop-blur-xl md:hidden'
        >
          <ul className='flex flex-col space-y-3 px-6'>
            {navItems.map(item => (
              <li key={item.href}>
                <Link
                  href={item.href}
                  onClick={() => setIsMobileMenuOpen(false)}
                  className='block rounded-lg py-2 px-4 text-white/80 transition-colors hover:bg-white/10'
                >
                  {item.label}
                </Link>
              </li>
            ))}
            <li className='pt-2 border-t border-white/10'>
              <AuthButton>
                {(user: User | null, _profile: UserProfile | null) => (
                  <>
                    {user ? (
                      <div className='space-y-2'>
                        <Link
                          href='/dashboard'
                          onClick={() => setIsMobileMenuOpen(false)}
                          className='flex items-center gap-2 rounded-lg py-2 px-4 text-white/80 transition-colors hover:bg-white/10'
                        >
                          <UserIcon className='h-4 w-4' />
                          Dashboard
                        </Link>
                        <button
                          onClick={handleSignOut}
                          className='flex w-full items-center gap-2 rounded-lg py-2 px-4 text-left text-red-400/80 transition-colors hover:bg-white/10'
                        >
                          <LogOut className='h-4 w-4' />
                          Sign Out
                        </button>
                      </div>
                    ) : (
                      <Link
                        href='/login'
                        onClick={() => setIsMobileMenuOpen(false)}
                        className='block rounded-lg py-2 px-4 text-white/80 transition-colors hover:bg-white/10'
                      >
                        Sign In
                      </Link>
                    )}
                  </>
                )}
              </AuthButton>
            </li>
          </ul>
        </motion.div>
      )}
    </motion.header>
  );
};
