'use client';

import { memo, type ReactNode } from 'react';

import { NavLink } from './NavLink';

interface FooterLinkProps {
  href: string;
  children: ReactNode;
  target?: string;
  rel?: string;
}

export const FooterLink = memo<FooterLinkProps>(({ href, children, target, rel }) => (
  <NavLink href={href} target={target} rel={rel}>
    {children}
  </NavLink>
));
