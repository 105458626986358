'use client';

import { motion } from 'framer-motion';

export default function ClientBackgroundAnimation() {
  return (
    <div className='fixed inset-0 z-[-1] overflow-hidden'>
      <motion.div
        className='absolute inset-0 opacity-50'
        animate={{
          background: [
            'radial-gradient(circle, rgba(29,78,216,0.15) 0%, transparent 100%)',
            'radial-gradient(circle, rgba(129,140,248,0.15) 0%, transparent 100%)',
            'radial-gradient(circle, rgba(67,56,202,0.15) 0%, transparent 100%)',
          ],
        }}
        transition={{ duration: 10, repeat: Number.POSITIVE_INFINITY, repeatType: 'reverse' }}
      />
      <div className="absolute inset-0 bg-[url('/grid.svg')] bg-fixed opacity-20" />
    </div>
  );
}
