'use client';

import React, { ReactNode } from 'react';
import type { LinkProps } from 'next/link';

interface ClientNavProps {
  children: ReactNode;
}
interface NavLinkProps extends LinkProps {
  children: ReactNode;
  className?: string;
}

export function ClientNav({ children }: ClientNavProps) {
  return (
    <ul className='flex items-center space-x-2 sm:space-x-3 md:space-x-6 text-xs sm:text-sm md:text-base'>
      {React.Children.map(children, child => {
        if (React.isValidElement<{ children?: ReactNode; className?: string }>(child)) {
          // Handle list items
          if (child.type === 'li') {
            const childContent = child.props.children;

            // Handle Link components inside list items
            if (
              React.isValidElement<NavLinkProps>(childContent) &&
              typeof childContent.type !== 'string' &&
              'displayName' in childContent.type &&
              childContent.type.displayName === 'Link'
            ) {
              return React.cloneElement(child, {
                className: 'hover:scale-105 transition-transform',
                children: childContent, // Preserve the original Link with its styles
              });
            }
          }

          // Pass through other elements (like AuthButton) unchanged
          return child;
        }

        return child;
      })}
    </ul>
  );
}
