'use client';

import { memo, type ReactNode } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

interface NavLinkProps {
  href: string;
  children: ReactNode;
  className?: string;
  target?: string;
  rel?: string;
}

export const NavLink = memo<NavLinkProps>(({ href, children, className = '', target, rel }) => {
  const pathname = usePathname();
  const isActive = pathname === href;

  return (
    <Link
      href={href}
      className={`relative text-gray-400 transition-all duration-200 after:absolute after:bottom-[-2px] after:left-0 after:right-0 after:h-[2px] after:transition-all after:duration-200 hover:text-gray-300 ${
        isActive
          ? 'text-white after:w-full after:bg-[#60A5FA]'
          : 'after:w-0 after:bg-[#60A5FA] hover:after:w-full'
      } ${className} `}
      target={target}
      rel={rel}
    >
      {children}
    </Link>
  );
});
