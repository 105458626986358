import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/ClientSideContent/ClientBackgroundAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/components/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/components/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LazyMotion","domAnimation"] */ "/app/node_modules/.pnpm/framer-motion@12.6.2_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/framer-motion/dist/es/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/.pnpm/sonner@2.0.2_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/sonner/dist/index.mjs");
