'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import { createClient } from '@/utils/supabase/client';
import { User } from '@supabase/supabase-js';

interface UserProfile {
  username?: string;
  avatar_url?: string;
  full_name?: string;
}

interface AuthButtonProps {
  children?: (user: User | null, profile: UserProfile | null) => React.ReactNode;
}

export const AuthButton = ({ children }: AuthButtonProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const supabase = createClient();

  const fetchUserProfile = async (user: User) => {
    try {
      const provider = user.app_metadata.provider;
      let userProfile: UserProfile = {};

      if (provider === 'github') {
        userProfile = {
          username: user.user_metadata.user_name,
          avatar_url: user.user_metadata.avatar_url,
          full_name: user.user_metadata.full_name,
        };
      } else if (provider === 'discord') {
        userProfile = {
          username: user.user_metadata.full_name,
          avatar_url: user.user_metadata.avatar_url,
          full_name: user.user_metadata.full_name,
        };
      }

      setProfile(userProfile);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      setIsAuthenticated(!!session);
      setUser(session?.user ?? null);

      if (session?.user) {
        fetchUserProfile(session.user);
      }

      setIsLoading(false);
    });

    // Initial session check
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => {
        setIsAuthenticated(!!session);
        setUser(session?.user ?? null);

        if (session?.user) {
          fetchUserProfile(session.user);
        }

        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error checking auth session:', error);
        setIsLoading(false);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [supabase.auth]);

  if (isLoading) {
    return null;
  }

  if (isAuthenticated && children) {
    return <li>{children(user, profile)}</li>;
  }

  return (
    <li>
      <Link href='/login'>
        <button
          type='button'
          className='glow rounded-full bg-white/10 px-4 py-2 text-white transition-colors hover:bg-white/20'
        >
          Login
        </button>
      </Link>
    </li>
  );
};
